import { useState, useEffect } from "react";
import styles from "styles/nav/Search.module.scss";
import SearchIcon from "images/Search.svg";
import { useRouter } from "next/router";
import StravitoHits from "./StravitoHits";
import Link from "next/link";

import { dataTopics } from "helpers/sampleData";

// Images
import DocumentIcon from "images/icon-file-document.svg";
import IconFile from "images/icon-file.svg";
import IconArticle from "images/icon-article.svg";
import IconBurger from "images/icon-burger.svg";
import IconBrief from "images/icon-brief.svg";
import IconCampaign from "images/icon-campaign.svg";
import IconFGD from 'images/icon-fgd.svg';
import IconDownload from 'images/icon-download.svg';
import IconGuideline from 'images/icon-guidelines.svg';
import IconCE from "images/icon-ce-tab.svg";
import UserAvatar from "images/icon-people-tab.svg";
import DropdownIcon from "images/icon-dropdown.svg";
import DOCX from "images/markdown.svg";
import FoodLibrary from "images/food-library.svg";

// Global State
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState, topicListState, isFGDUserState } from "state/atoms";

// Search
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
} from "react-instantsearch-dom";
import { ApiResponse, IfaceAllNewestResponse, typeActiveView } from "helpers/interfaces";
import { fetchHappyMealPage, slugify } from "helpers/functions";
// import { getElementById } from "domutils";

// TODO: Remove demo hardcoded keys
const algolia_app_id: string = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
const algolia_api_key: string = process.env
  .NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string;
const algolia_articles = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT;
const algolia_users: string = process.env.NEXT_PUBLIC_ALGOLIA_USERS as string;
const algolia_ce: any = process.env.NEXT_PUBLIC_ALGOLIA_CE;
const algolia_stravito: any = process.env.NEXT_PUBLIC_STRAVITO_INDEX;
const algolia_fgd: any = process.env.NEXT_PUBLIC_FGD_INDEX;

const searchClient = algoliasearch(algolia_app_id, algolia_api_key);

type Props = {
  color?: string;
};

const UserResults = (props: any) => {
  const inputValue = props.inputValue;

  return (
    <>
      <InstantSearch
        indexName={algolia_users}
        searchClient={searchClient}>
        <Configure
          hitsPerPage={5}
          filters={'employee_type:corporate'}
        />
        <div className={styles.peopleSearchbox}>
          <SearchBox
            defaultRefinement={inputValue}
          />
        </div>
        {inputValue && (
          <>
            <span className={`caption small ${styles.caption}`}>People</span>
            {inputValue && <Hits hitComponent={UserHit} />}
          </>
        )}
      </InstantSearch>
    </>
  )
}

const CEResults = (props: any) => {
  const inputValue = props.inputValue;

  return (
    <>
      <InstantSearch
        indexName={algolia_ce}
        searchClient={searchClient}>
        <Configure
          hitsPerPage={5}
          filters={'review_status:published'}
        />
        <div className={styles.resultSearchbox}>
          <SearchBox
            defaultRefinement={inputValue}
          />
        </div>
        {inputValue && (
          <>
            <span className={`caption small ${styles.caption}`}>Briefs and Campaigns</span>
            {inputValue && <Hits hitComponent={Hit} />}
          </>
        )}
      </InstantSearch>
    </>
  )
}

const DocumentsResults = (props: any) => {
  const inputValue = props.inputValue;

  return (
    <>
      <InstantSearch
        indexName={algolia_stravito}
        searchClient={searchClient}>
        <Configure
          hitsPerPage={5}
        />
        <div className={styles.resultSearchbox}>
          <SearchBox
            defaultRefinement={inputValue}
          />
        </div>
        {inputValue && (
          <>
            <span className={`caption small ${styles.caption}`}>Documents</span>
            {inputValue && <Hits hitComponent={Hit} />}
          </>
        )}
      </InstantSearch>
    </>
  )
}

const FGDResults = (props: any) => {
  const inputValue = props.inputValue;

  return (
    <>
      <InstantSearch
        indexName={algolia_fgd}
        searchClient={searchClient}>
        <Configure
          hitsPerPage={5}
          filters={'is_published:1 AND (review_status:published OR review_status:review_complete)'}
        />
        <div className={styles.resultSearchbox}>
          <SearchBox
            defaultRefinement={inputValue}
          />
        </div>
        {inputValue && (
          <>
            <span className={`caption small ${styles.caption}`}>Feel-Good Design</span>
            {inputValue && <Hits hitComponent={Hit} />}
          </>
        )}
      </InstantSearch>
    </>
  )
}

export default function Search({ color }: Props) {
  const router = useRouter();
  let classes = styles.searchbar;
  if (color === "dark") classes += ` ${styles.dark}`;

  const [showPopularSearches, setShowPopularSearches] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");

  // // TODO: Remove or Refine Easter Egg
  // // Ignore this easter egg code
  // let easterEgg: string = "";
  // if (
  //     inputValue === "clique studios" ||
  //     inputValue === "kindle communications"
  // ) {
  //     easterEgg = "Easter Egg Demo!";
  // } else {
  //     easterEgg = "";
  // }
  // // Told you to ignore it
  const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);
  const [isCorporate, setIsCorporate] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [filterString, setFilterString] = useState<string>('');
  useEffect(() => {
    if (currentUser && !isCorporate) {
      // if ((currentUser.employee_type && currentUser.employee_type === 'corporate') &&
      //   (currentUser.email && !currentUser.email.includes('#EXT#') &&
      //     !currentUser.email.includes('#ext#') &&
      //     !currentUser.email.includes('@external.mcd.com'))) {
      //   setIsCorporate(true);
      // }
      setIsCorporate(true); //#TODO Temp fix. Remove when permanent fix is in place
    }
  }, [currentUser]);

  let defaultState: any = {
    query: '',
    page: 1,
    refinementList: {
      'object_type': ['article', 'happy_meal', 'stravito', 'global_food_marketing']
    },
    sortBy: `${algolia_articles}`,
  };

  const checkCorporate = () => {
    if (!isCorporate && defaultState) {
      if (defaultState.refinementList.object_type.indexOf('stravito') > -1) {
        defaultState.refinementList.object_type.splice(defaultState.refinementList.object_type.indexOf('stravito'), 1);
      }

      if (defaultState.refinementList.object_type.indexOf('global_food_marketing') > -1) {
        defaultState.refinementList.object_type.splice(defaultState.refinementList.object_type.indexOf('global_food_marketing'), 1);
      }
    }
  }

  const [searchState, setSearchState] = useState<any>(defaultState);

  const onSearchStateChange = (updatedSearchState: any, query: any) => {
    let newState: any = {
      query: updatedSearchState.query,
      page: 1,
      refinementList: {
        'object_type': ['article', 'happy_meal']
      },
      sortBy: `${algolia_articles}`,
    };

    if (isCorporate) {
      newState.refinementList.object_type.push('stravito');
      newState.refinementList.object_type.push('global_food_marketing');
      setFilterString('');
    } else {
      setFilterString(`(object_type:"article" OR object_type:"happy_meal")`);
    }

    setSearchState(newState);
  }

  useEffect(() => {
    checkCorporate();
  }, [isCorporate]);

  let resultTypes: any = [
    { val: 'all', title: 'All content' },
    { val: 'articles', title: 'Articles' },
    { val: 'creative-effectiveness', title: 'Briefs & Campaigns' },
    { val: 'feel-good-design', title: 'Feel-Good Design' },
    { val: 'documents', title: 'Documents' },
  ];
  
  if(isFGDUser) {
    resultTypes = [
      { val: 'all', title: 'All content' },
      { val: 'articles', title: 'Articles' },
      { val: 'creative-effectiveness', title: 'Briefs & Campaigns' },
      { val: 'feel-good-design', title: 'Feel-Good Design' },
      { val: 'documents', title: 'Documents' },
    ]
  }

  const [activeType, setActiveType] = useState<any>({ val: 'all', title: 'All Content' });
  const [showResultTypes, setShowResultTypes] = useState<boolean>(false);

  document.addEventListener('click', (e: any) => {
    const selectType: any = document.getElementById('selectType');
    if (selectType && !selectType.contains(e.target) && !e.target.classList.contains('typeOption')) {
      setShowResultTypes(false);
    }
  });

  const handleClick: (e: any) => void = (e) => {
    e.preventDefault();
    // const value = e.currentTarget.querySelector("input[type=search]").value;
    // console.log("event value is: ", value);
    if (inputValue) {
      let newRoute = `/search/${inputValue}`;
      if(isFGDUser) {
        newRoute = `/search/design/${inputValue}`;
      }
      if (activeType && activeType.val !== 'all') {
        newRoute += `?result_type=${activeType.val}`;
      }
      // console.log('newRoute', newRoute);
      router.push(newRoute);
    }
  };

  const [searchURL, setSearchURL] = useState<string>(isFGDUser ? '/search' : '/search/design');

  useEffect(() => {
    let updatedURL: string = '/search/' + inputValue;
    if(isFGDUser) {
      updatedURL = '/search/design/' + inputValue;
    }
    if (activeType.val !== 'all') {
      updatedURL += '?result_type=' + activeType.val;
    }
    setSearchURL(updatedURL);
  }, [inputValue, activeType]);


  const localStorageSearch: any = localStorage.getItem('recent-searches');
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
	const recentSearchStorage = JSON.parse(localStorageSearch);
	if (recentSearchStorage) {
		setRecentSearches(recentSearchStorage.reverse());
	}
  }, []);

  const removeSearchTerm = (item: any) => {
	let updatedSearch = recentSearches.filter((el: any) => !el.includes(item));
	setRecentSearches(updatedSearch.reverse());
	localStorage.setItem('recent-searches', JSON.stringify(updatedSearch.reverse()));
}

  return (	
    <div className={classes}>
      {/* <form action="" className="search"> */}
      <div id={'nav-search-container'} className={styles.searchContainer}>
        <InstantSearch
          indexName={`${algolia_articles}`}
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}>
          <Configure
            hitsPerPage={5}
            filters={filterString}
			query={inputValue}
          />
          <div className={`searchBarContainer ${styles.searchBarContainer}`}>
			<div className="ais-SearchBox">
				<form className="ais-SearchBox-form" onSubmit={(event: any) => handleClick(event)}>
					<input type="search" placeholder="Search" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="ais-SearchBox-input" value={inputValue} onFocus={(e: any) => setShowPopularSearches(true)} onChange={(e: any) => setInputValue(e.target.value)} />
				</form>
			</div>
            {!isFGDUser && (
              <div id={'selectType'} className={styles.selectType}>
                <div className={styles.selectTypeInner}>
                  <button onClick={(e: any) => {
                    e.preventDefault();
                    setShowResultTypes(!showResultTypes);
                  }}>
                    <span>{activeType.title}</span>
                    <DropdownIcon />
                  </button>
                  {resultTypes && showResultTypes && (
                    <ul>
                      {resultTypes.map((item: any) => {
                        if (item.val !== activeType.val) {
                          return (
                            <li className={item.val === activeType.val ? styles.activeType : ''}>
                              <label onClick={(e: any) => {
                                setActiveType({ val: item.val, title: item.title });
                                setShowResultTypes(!showResultTypes);
                              }} className={'typeOption'}>
                                <input type={'radio'} name={'resultType'} value={item.val} checked={item.val === activeType.val} />
                                {item.title}
                              </label>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
		  {showPopularSearches && !inputValue && recentSearches && recentSearches.length > 0 && (
			<div className={styles.results}>
				<div className={styles.searchResults}>
					<span className={`caption small ${styles.caption}`}>Recent Searches</span>
					{recentSearches.map((item: any, index: any) => {
						let url = searchURL + '/' + item;
                        return (
							<div className={styles.searchResultsItem} key={index}>
								<Link href={url}>
									<a className={styles.searchResultsLink}>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="#fff"/><path d="M6.615 7.493a7.025 7.025 0 1 0 10.35-.458A7.033 7.033 0 0 0 12 4.975a.437.437 0 0 0 0 .874 6.149 6.149 0 1 1-4.686 2.168l.093.944a.437.437 0 0 0 .434.393h.045a.44.44 0 0 0 .367-.528l-.184-1.873V6.857h.002l-.003-.008a.434.434 0 0 0-.28-.254v-.001h-.125l-1.883.024v.025a.412.412 0 0 0 0 .824l.835.025Z" fill="#878787" stroke="#878787" stroke-width=".05"/><path d="M11 13.097v.038l.014.038a.194.194 0 0 0 0 .036.223.223 0 0 1 .019.036l.022.033.027.03.028.027.03.025.036.025h.019l2.764 1.433c.059.03.124.046.19.046a.415.415 0 0 0 .189-.78l-2.514-1.303V9.01a.412.412 0 0 0-.824 0v4.042c0 .022.005.03.005.044H11Z" fill="#878787"/></svg>
										{item.replace(/-/g, ' ')}
									</a>
								</Link>
								<button className={styles.searchResultsRemove} onClick={(e: any) => {
									e.preventDefault();
									removeSearchTerm(item);
								}}><span>+</span></button>
							</div>
                          )
                      })}
				</div>
			</div>
			)}
          {inputValue && (
            <div className={styles.results}>
              <div className={styles.searchResults}>
                {!isFGDUser && (activeType.val === 'all' || activeType.val === 'articles') && (
                  <span
                    className={`caption small ${styles.caption}`}>
                    Articles & Files
                  </span>
                )}
                {!isFGDUser && inputValue && (activeType.val === 'all' || activeType.val === 'articles') && <Hits hitComponent={Hit} />}
                {!isFGDUser && inputValue && (activeType.val === 'all' || activeType.val === 'creative-effectiveness') && <CEResults inputValue={inputValue} />}
                {inputValue && (activeType.val === 'all' || activeType.val === 'feel-good-design') && <FGDResults inputValue={inputValue} />}
                {!isFGDUser && inputValue && (activeType.val === 'all' || activeType.val === 'documents') && <DocumentsResults inputValue={inputValue} />}
              </div>
              <span className={styles.seeAll}>
                <Link href={searchURL}>
                  <a className="btn">See All Results</a>
                </Link>
              </span>
            </div>
          )}
        </InstantSearch>
      </div>
    </div>
  );
}

function Hit(props: any) {
  const topics = useRecoilValue(topicListState);
  const [slug, setSlug] = useState<any>(props.hit.slug || null);

  const getHappyMealPage = async ({ slug }: any) => {
    const data: IfaceAllNewestResponse = await fetchHappyMealPage(slug);
    if (!data.error) {
      const result: any = data.res.data;
      const timestamp = new Date(result.published_at.replace(' K', '')).getTime() / 1000;
    }
  };

  useEffect(() => {
    if (props.hit.object_type && props.hit.object_type === 'happy_meal') {
      // const happySlug: any = slug ? slug : slugify(props.hit.title);
      // if (slug !== happySlug) {
      //   setSlug(happySlug);
      // }
      // getHappyMealPage({ slug: slug });
    }
  });

  let link: any = `/articles/${slug}`;
  if (props.hit.object_type === 'stravito' || props.hit.object_type === 'global_food_marketing' || props.hit.object_type === 'global_family_marketing') {
    link = props.hit.url;
  } else if (props.hit.object_type === 'happy_meal_readers') {
    link = `/reader/${slug}`;
  } else if (props.hit.object_type === 'happy_meal') {
    link = `/happy-meal/${slug}`;
  } else if (props.hit.object_type === 'campaign' || props.hit.object_type === 'campaigns') {
    link = `/creative/${slug}`;
  } else if (props.hit.object_type === 'brief' || props.hit.object_type === 'briefs') {
    link = `/brief/${slug}`;
  } else if (props.hit.object_type === 'guidelines'){
    link = `/guidelines/${slug}`;
  } else if (props.hit.object_type === 'downloads'){
    link = `/downloads/${slug}`;
  }

  // console.log(props.hit);
  let typeIcon: any = <IconArticle />;
  let target: any = '';
  switch (props.hit.object_type) {
    case 'stravito':
      typeIcon = <IconFile />;
      target = '_blank';
      break;
    case 'global_food_marketing':
      typeIcon = <IconBurger />;
      target = '_blank';
      break;
    case 'global_family_marketing':
      typeIcon = <IconBurger />;
      target = '_blank';
      break;
    case 'brief':
      typeIcon = <IconBrief />;
      break;
    case 'campaign':
      typeIcon = <IconCampaign />
      break;
    case 'campaigns':
      typeIcon = <IconCampaign />
      break;
    case 'downloads':
      typeIcon = <IconDownload />
      break;
    case 'guidelines':
      typeIcon = <IconGuideline />
      break;
  }

  if (props.hit) {
    // console.log('props.hit', props.hit);
    return (
      <a className={styles.hit} href={link} target={target}>
        <span className={styles.hitTitle}>
          <span className={styles.iconContainer}>
            {typeIcon}
          </span>
          <Highlight hit={props.hit} attribute="title" />
        </span>{" "}
        {props.hit.topic && props.hit.topic.length > 0 && (
          <span className={styles.hitTopic}>
            {Array.isArray(props.hit.topic) ? props.hit.topic.join(', ') : props.hit.topic}
          </span>
        )}
        {props.hit.object_type && props.hit.object_type === 'happy_meal' && (
          <span className={styles.hitTopic}>Global Family Marketing</span>
          )}
        {props.hit.object_type === 'guidelines' && (
          <span className={styles.hitTopic}>Guidelines</span>
        )}
        {props.hit.object_type === 'downloads' && (
          <span className={styles.hitTopic}>Downloads</span>
        )}
        {props.hit.object_type === 'webcast' && (
            <span className={styles.hitTopic}>Webcasts</span>
        )}
      </a>
    );
  } else {
    return <h2>Search Error</h2>;
  }
}

function UserHit(props: any) {
  if (props.hit) {
    return (
      <a className={styles.hit} href={`/profile/${props.hit.id}`}>
        <span className={styles.hitTitle}>
          <span className={styles.iconContainer}>
            <UserAvatar />
          </span>
          <span className={'title'}>{props.hit.first_name} {props.hit.last_name}</span>
        </span>{" "}
      </a>
    );
  } else {
    return <h2>Search Error</h2>;
  }
}
